<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span @keydown.stop="" @keyup.stop="" @click.stop=""
              >项目名称：
              <el-autocomplete
                style="width: 500px; margin-right: 10px"
                class="ipt_width project_width"
                v-model="parameter.projectName"
                :fetch-suggestions="projectQuerySearch"
                placeholder="请输入或选择项目名"
                @select="onProkectNameSelect"
                :popper-append-to-body="false"
              >
              </el-autocomplete>
            </span>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset"
            >清空</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column
              align="center"
              prop="projectName"
              min-width="320"
              fixed="left"
              label="项目名称"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="isKeyProject"
              min-width="100"
              label="是否是重点项目"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.isKeyProject == 0">否</span>
                <span v-else style="color: red">是</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="chairmanScore"
              min-width="100"
              label="董事长评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.chairmanScore">{{
                  scope.row.chairmanScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isChairmanScore === '已评分' }"
                  v-else
                >
                  {{ scope.row.isChairmanScore }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="managerScore"
              min-width="100"
              label="分管高管评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.managerScore">{{
                  scope.row.managerScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isManagerScore === '已评分' }"
                  v-else
                  >{{ scope.row.isManagerScore }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="departmentScore"
              min-width="100"
              label="部门经理评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.departmentScore">{{
                  scope.row.departmentScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isDepartmentScore === '已评分' }"
                  v-else
                  >{{ scope.row.isDepartmentScore }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="businessScore"
              min-width="100"
              label="商务负责人评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.businessScore">{{
                  scope.row.businessScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isBusinessScore === '已评分' }"
                  v-else
                  >{{ scope.row.isBusinessScore }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="qualityScore"
              min-width="100"
              label="质检负责人评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.qualityScore">{{
                  scope.row.qualityScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isQualityScore === '已评分' }"
                  v-else
                  >{{ scope.row.isQualityScore }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="archiveScore"
              min-width="100"
              label="成果归档评分"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.archiveScore">{{
                  scope.row.archiveScore
                }}</span>
                <span
                  :class="{ isScore: scope.row.isArchiveScore === '已评分' }"
                  v-else
                  >{{ scope.row.isArchiveScore }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="totalScore"
              min-width="100"
              label="综合评分"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              min-width="100"
              fixed="right" 
              label="操作"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleDetail(scope.row)"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input
                size="mini"
                v-model.number="pageSize"
                @blur="handlePageSize"
              ></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit
      :isShow.sync="showEditDialog"
      mode="view"
      ref="projectEdit"
      :StautsType="StautsType"
      :ProjectType="ProjectType"
      :options="options"
      toTabName="endScore"
    ></Edit>
  </div>
</template>

<script>
import { initTreeData } from "@/util/common";
import { mapState } from "vuex";
export default {
  components: {
    Edit: () => import("../Edit.vue"),
    Crumbs: () => import("@/components/Crumbs.vue"),
  },
  data() {
    return {
      loading: false,
      showEditDialog: false,
      parameter: {
        projectName: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      tableData: [],
      StautsType: [],
      ProjectType: [],
      options: {},
      projectList: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog;
    this.parameter.pageSize = this.userInfo.pageSizeLog;
    this.getData();
    this.getDictData();
  },
  provide() {
    return {
      refresh: this.refresh,
    };
  },
  methods: {
    getProject() {
      this.$api.project
        .listProject({ pageSize: 10000 })
        .then(res => {
          this.projectList = res.data.records
        })
        .catch(err => {
          console.log(err)
        })
    },
    projectQuerySearch(queryString, cb) {
      let projectList = this.projectList.map(item => {
        return { value: item.projectName }
      })
      let results = queryString ? this.projectCreateFilter(queryString) : projectList
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    projectCreateFilter(queryString) {
      let results = this.projectList.map(item => {
        return { value: item.projectName }
      })
      // 将输入的内容进行拆分，返回值为一个数组
      const queryStringList = queryString.split('')
      if (queryStringList.length > 0) {
        queryStringList.forEach(queryItem => {
          let filterResults = results.filter(nameItem => nameItem.value?.includes(queryItem))
          results = [...filterResults]
        })
      }
      return results
    },
    onProkectNameSelect(e) {
      if (e) {
        this.parameter.projectName = e.value
      }
      this.$forceUpdate()
    },
    handleDetail(row) {
      row.ProjectDisable = 4;
      row.isContractInformation = 1;
      row.isRecord = true; //项目操作记录显示
      this.options = row.deepClone();
      this.options.id = this.options.projectId;
      this.options.isViewMonitoring = true;
      // this.$refs.projectEdit.activeName = '项目评分'
      this.showEditDialog = true;
    },
    refresh() {
      this.showEditDialog = false;
      this.pageChangeHandler(1);
    },
    getDictData() {
      this.getProject()
      this.$api.dict
        .listSysDictData("PROJECT_STATUS", true)
        .then((res) => {
          // PROJECT_STATUS 项目阶段状态
          this.StautsType = initTreeData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleReset() {
      this.parameter.projectName = ''
      this.getData();
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val;
      this.getData();
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val;
      this.parameter.pageNow = 1;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.$api.project.listProjectScore(this.parameter).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.parameter.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    handlePageSize(val) {
      console.log(val);
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return;
      }
      if (!this.pageSize) {
        this.pageSize = 1;
      }
      let obj = { pageSizeLog: this.pageSize };
      this.loading = true;
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then((res) => {
          this.loading = false;
          this.parameter.pageNow = 1;
          this.parameter.pageSize = this.pageSize;
          this.userInfo.pageSizeLog = this.pageSize;
          localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
          this.getData();
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.isScore {
  color: skyblue;
}
</style>
